/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [{
        url: "/",
        name: "Home",
        slug: "home",
        icon: "HomeIcon",
        i18n: "Home",
        show: true
    },

    // {
    //   url: null,
    //   name: "Dashboard",
    //   show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
    //   // tag: "2",
    //   // tagColor: "primary",
    //   icon: "BarChart2Icon",
    //   i18n: "Dashboard",
    //   submenu: [
    //     {
    //       url: "/dashboard/analytics",
    //       name: "Analytics",
    //       slug: "dashboard-analytics",
    //       i18n: "Analytics"
    //     }
    //   ]
    // },

    {
        show: false,
        url: "/MedicalWebinar",
        name: "MedicalWebinar",
        slug: "MedicalWebinar",
        icon: "GridIcon",
        i18n: "Medical Webinar"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanager" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanagersupervisor",
        url: "/Doctor",
        name: "Doctor",
        slug: "Doctor",
        icon: "GridIcon",
        i18n: "doctors"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "patientserviceagent" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "agentssupervisor",
        url: "/Patient",
        name: "Patient",
        slug: "Patient",
        icon: "GridIcon",
        i18n: "Patient"
    },

    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanager" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanagersupervisor"
            ||JSON.parse(localStorage.getItem("userInfo")).userRole == "hospitalgroup",
        url: "/Hospital",
        name: "Hospital",
        slug: "Hospital",
        icon: "GridIcon",
        i18n: "Hospital"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "patientserviceagent"
            ||JSON.parse(localStorage.getItem("userInfo")).userRole == "hospitalgroup",
        url: "/GeneralReservation",
        name: "GeneralReservation",
        slug: "GeneralReservation",
        icon: "GridIcon",
        i18n: "Booking"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/MedicalPackage",
        name: "MedicalPackage",
        slug: "MedicalPackage",
        icon: "GridIcon",
        i18n: "Medical Package"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "patientserviceagent" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "agentssupervisor",
        url: "/ReservationMedicalPackage",
        name: "ReservationMedicalPackage",
        slug: "ReservationMedicalPackage",
        icon: "GridIcon",
        i18n: "Reserved Medical Package"
    },

    {
        //show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        name: "Reservation",
        // tag: "1",
        // tagColor: "primary",
        show: false,
        icon: "BookIcon",
        i18n: "Reservation",
        submenu: [
            // {
            //   url: "/DoctorSession",
            //   name: "DoctorSession",
            //   slug: "DoctorSession",
            //   icon: "GridIcon",
            //   i18n: "Doctor Session"
            // },
            // {
            //   url: "/Reservation",
            //   name: "Reservation",
            //   slug: "Reservation",
            //   icon: "GridIcon",
            //   i18n: "Reservation"
            // },

            {
                url: "/ReservationSession",
                name: "ReservationSession",
                slug: "ReservationSession",
                icon: "GridIcon",
                i18n: "Reservation Session"
            },
            {
                url: "/ReservationSurgery",
                name: "ReservationSurgery",
                slug: "ReservationSurgery",
                icon: "GridIcon",
                i18n: "Reservation Surgery"
            },
            //   {
            //     url: "/FillterReservationSession",
            //     name: "FillterReservationSession",
            //     slug: "FillterReservationSession",
            //     icon: "GridIcon",
            //     i18n: "Fillter"
            //  }
        ]
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/FillterReservationSession",
        name: "Reservations With Language",
        slug: "ReservationsWithLanguage",
        icon: "GridIcon",
        i18n: "Reservations With Language"
    },

    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "agentssupervisor" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/AgentPatientRate",
        name: "Agent Patient Rate",
        slug: "AgentPatientRate",
        icon: "GridIcon",
        i18n: "Agent Patient Rate"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanagersupervisor" ||
            JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/AccountManagerRate",
        name: "Account Manager Rate",
        slug: "AccountManagerRate",
        icon: "GridIcon",
        i18n: "Account Manager Rate"
    },

    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/LiveSessions",
        name: "Live Sessions",
        slug: "LiveSessions",
        icon: "GridIcon",
        i18n: "Live Sessions"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/AppointmentMarkup",
        name: "Appointment Markups",
        slug: "AppointmentMarkup",
        icon: "GridIcon",
        i18n: "Appointment Markup"
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/SurgeryMarkup",
        name: "Surgery  Markups",
        slug: "SurgeryMarkup",
        icon: "GridIcon",
        i18n: "Surgery Markup "
    },
    {
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        url: "/TapPayment",
        name: "Tap Payment",
        slug: "TapPayment",
        icon: "GridIcon",
        i18n: "Tap Payment"
    },
    {
        url: null,
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        name: "Equation",
        // tag: "4",
        // tagColor: "primary",
        icon: "GridIcon",
        i18n: "Finanical Report",
        submenu: [{
                url: "/Invoices",
                name: "Invoices",
                slug: "Invoices",
                icon: "",
                i18n: "Invoices "
            },
            {
                url: "/ProfiteReport",
                name: "ProfiteReport",
                slug: "ProfiteReport",
                icon: "",
                i18n: "Profit Report"
            },
            {
                url: "/settlementReport",
                name: "settlementReport",
                slug: "settlementReport",
                icon: "",
                i18n: "settlement Report"
            }
        ]
    },
    {
        url: null,
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        name: "Setting",
        // tag: "4",
        // tagColor: "primary",
        icon: "SettingsIcon",
        i18n: "Setting",
        submenu: [{
                url: "/Setting/GeneralSetting",
                name: "GeneralSetting",
                slug: "GeneralSetting",
                icon: "GridIcon",
                i18n: "General Setting"
            }, {
                url: "/Setting/PriceManagement",
                name: "PriceManagement",
                slug: "Country",
                icon: "GridIcon",
                i18n: "Price Management"
            },
            {
                url: "/Setting/MedicalPackageCategory",
                name: "MedicalPackageCategory",
                slug: "MedicalPackageCategory",
                icon: "GridIcon",
                i18n: "Medical Package Category "
            },
            {
                url: "/Setting/Country",
                name: "Country",
                slug: "Country",
                icon: "GridIcon",
                i18n: "Country"
            },
            {
                url: "/Setting/HospitalGroup",
                name: "HospitalGroup",
                slug: "HospitalGroup",
                icon: "GridIcon",
                i18n: "HospitalGroup"
            },
            {
                url: "/Setting/Region",
                name: "Region",
                slug: "Region",
                icon: "GridIcon",
                i18n: "Region"
            },
            {
                url: "/Setting/Diagnosis",
                name: "Diagnosis",
                slug: "Diagnosis",
                icon: "GridIcon",
                i18n: "Diagnosis"
            },
            {
                url: "/Setting/Medicine",
                name: "Medicine",
                slug: "Medicine",
                icon: "GridIcon",
                i18n: "Medicine"
            },

            {
                url: "/Setting/City",
                name: "City",
                slug: "City",
                icon: "GridIcon",
                i18n: "Cities"
            },
            {
                url: "/Setting/Currency",
                name: "Currency",
                slug: "Currency",
                icon: "GridIcon",
                i18n: "Currency"
            },
            {
                url: "/Setting/Language",
                name: "Language",
                slug: "Language",
                icon: "GridIcon",
                i18n: "Language"
            },
            // {
            //   url: "/Setting/Status",
            //   name: "Status",
            //   slug: "Status",
            //   icon: "GridIcon",
            //   i18n: "Status"
            // },
            {
                url: "/Setting/Specialty",
                name: "Specialty",
                slug: "Specialty",
                icon: "GridIcon",
                i18n: "Specialty"
            },
            // {
            //     url: "/Setting/Services",
            //     name: "Services",
            //     slug: "Services",
            //     icon: "GridIcon",
            //     i18n: "Services"
            // },
            {
                url: "/Setting/ExtraService",
                name: "ExtraService",
                slug: "ExtraService",
                icon: "GridIcon",
                i18n: "Surgery Extra Services"
            },
            // {
            //   url: "/Setting/PaymentType",
            //   name: "PaymentType",
            //   slug: "PaymentType",
            //   icon: "GridIcon",
            //   i18n: "Payment Type"
            // },
            // {
            //   url: "/Setting/PaymentStatus",
            //   name: "PaymentStatus",
            //   slug: "PaymentStatus",
            //   icon: "GridIcon",
            //   i18n: "Payment Status"
            // },
            // {
            //     url: "/Setting/DoctorRequestType",
            //     name: "DoctorRequestType",
            //     slug: "DoctorRequestType",
            //     icon: "GridIcon",
            //     i18n: "Doctor Request Type"
            // },
            {
                url: "/Setting/SmokeDegree",
                name: "SmokeDegree",
                slug: "SmokeDegree",
                icon: "GridIcon",
                i18n: "Smoke Degree"
            },
            {
                url: "/Setting/Position",
                name: "Position",
                slug: "Position",
                icon: "GridIcon",
                i18n: "Position"
            },
            {
                url: "/Setting/KinshipType",
                name: "KinshipType",
                slug: "KinshipType",
                icon: "GridIcon",
                i18n: "KinshipType"
            },
            {
                url: "/Setting/ReportType",
                name: "ReportType",
                slug: "ReportType",
                icon: "GridIcon",
                i18n: "Report Type"
            },
            {
                url: "/Setting/ScanType",
                name: "ScanType",
                slug: "ScanType",
                icon: "GridIcon",
                i18n: "Scan Type"
            },
            {
                url: "/Setting/AnalysisType",
                name: "AnalysisType",
                slug: "AnalysisType",
                icon: "GridIcon",
                i18n: "Analysis Type"
            },
            // {
            //   url: "/Setting/DocumentType",
            //   name: "DocumentType",
            //   slug: "DocumentType",
            //   icon: "GridIcon",
            //   i18n: "Document Type"
            // },
            {
                url: "/Setting/Department",
                name: "Department",
                slug: "Department",
                icon: "GridIcon",
                i18n: "Department"
            },
            {
                url: "/Setting/Sponsors",
                name: "Sponsors",
                slug: "Sponsors",
                icon: "GridIcon",
                i18n: "Sponsors"
            },
            {
                url: "/Setting/Surgeries",
                name: "Surgeries",
                slug: "Surgeries",
                icon: "GridIcon",
                i18n: "Surgeries"
            },
            {
                url: "/Setting/SurgeryCategories",
                name: "SurgeryCategories",
                slug: "SurgeryCategories",
                icon: "GridIcon",
                i18n: "Surgery Categories"
            },
            {
                url: "/Setting/SurgeryItems",
                name: "SurgeryItems",
                slug: "SurgeryItems",
                icon: "GridIcon",
                i18n: "Surgery Items"
            },
            {
                url: "/Setting/PaymentPartItem",
                name: "PaymentPartItem",
                slug: "PaymentPartItem",
                icon: "GridIcon",
                i18n: "Payment Part Item"
            },
            {
                url: "/Setting/UserNotificationSetting",
                name: "UserNotificationSetting",
                slug: "UserNotificationSetting",
                icon: "GridIcon",
                i18n: "User Notification Setting"
            },
            {
                url: "/Setting/InsuranceCompany",
                name: "InsuranceCompany",
                slug: "InsuranceCompany",
                icon: "GridIcon",
                i18n: "Insurance Company"
            },

        ]
    },
    {
        url: null,
        show: JSON.parse(localStorage.getItem("userInfo")).userRole == "admin",
        name: "Users",
        // tag: "1",
        // tagColor: "primary",
        icon: "UserIcon",
        i18n: "Users",
        submenu: [{
            url: "/user",
            name: "UserManagement",
            slug: "user management",
            icon: "GridIcon",
            i18n: "User Management"
        }]
    }

    // {
    //   url: null,
    //   name: "Reports",
    //   show:JSON.parse(localStorage.getItem("userInfo")).userRole=='admin',
    //   // tag: "2",
    //   // tagColor: "primary",
    //   icon: "TrendingUpIcon",
    //   i18n: "Reports",
    //   submenu: [
    //     {
    //       url: '/CourseCatalogReport',
    //       name: "CourseCatalog",
    //       slug: "dashboard-analytics",
    //       i18n: "Course Catalog",
    //     },
    //   ]
    // },
];